.custom-file-input-css::-webkit-file-upload-button {
	visibility: hidden;
}
.custom-file-input-css::before {
	content: "Chọn file";
	display: inline-block;
	background: linear-gradient(top, #f9f9f9, #e3e3e3);
	border: 1px solid #999;
	border-radius: 3px;
	padding: 5px 8px;
	outline: none;
	white-space: nowrap;
	-webkit-user-select: none;
	cursor: pointer;
	text-shadow: 1px 1px #fff;
	font-weight: 700;
	font-size: 10pt;
}
.custom-file-input-css:hover::before {
	border-color: black;
}
.custom-file-input-css:active::before {
	background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

th, td {
	min-width: 200px !important;
	font-size: 12px;
}
.custom-input-calendar {
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;
  max-height: 36px;
  height: 36px;
  font-size: 13px;
  padding: 0px 8px;
}

.icon-calendar .react-datepicker__input-container::after {
	content: url(./assets/svg/calendar.svg);
	width: 14px;
	height: 14px;
	position: absolute;
	top: 0.5rem;
	right: 8px;
}

.border-red {
	border: 1px solid red !important;
}

.border-gray {
	border: 1px solid #DEE2E6;
}

.input-date-picker-custom {
	padding: 5px 16px 5px 8px !important;
	border-radius: 5px;
	font-size: 0.875rem;
	min-width: 100%;
	width: 100%;
	height: 36px;
	border: 1px solid #DEE2E6;
}

.react-datepicker__input-container {
	position: relative;
}

.is-open-month-container .react-datepicker__month-container{
	display: none;
}
.is-open-month-container .react-datepicker__navigation{
	display: none;
}
.is-open-month-container .react-datepicker__triangle{
	display: none;
}

input {
	max-height: 36px;
}



/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  width: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  width: 5px;
}

.required {
	position: relative;
}
.required::after {
	content: "*";
	position: absolute;
	top: 0;
	left: 100%;
	color: red;
}

.react-select-error div {
  border-color: #e74c3c; 
}

.react-select-error:hover div {
  border-color: #e74c3c; 
}

.approve {
	cursor: pointer;
	transition: 200ms;
}

.approve:hover {
	text-decoration: underline;
}

.min-w-400 {
	min-width: 400px !important;
}

.min-w-300 {
	min-width: 300px !important;
}